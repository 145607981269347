import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataMasterService, USER_TYPE_USER, USER_TYPE_MOD, USER_TYPE_ADMIN } from 'src/app/services/data-master.service';
import { Order } from '../../classes/order.class';
import { Grade } from '../../classes/grade.class';
import { Request } from '../../classes/request.class';
import { Profit } from '../../classes/profit.class';
import { Seller } from '../../classes/seller.class';
import { Product } from '../../classes/product.class';
import { Moderator } from '../../classes/moderator.class';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  readonly typeUser = USER_TYPE_USER;
  readonly typeMod = USER_TYPE_MOD;
  readonly typeAdmin = USER_TYPE_ADMIN;

  order : boolean = true;
  userType : string;
  orderAdminList : Order[] = [];
  requestModList : Request[] = [];
  requestUserList: Request[] = [];
  user_profit : Profit[] = [];
  seller : Seller[] = [];
  moderators : Moderator[] = [];

  productAdminList: Product[]=[];
  date;

  constructor(public dataService : DataMasterService ,private activatedRoute: ActivatedRoute) {
    this.dataService.setFooter("fixed");
    this.activatedRoute.params.subscribe(params =>{
      const loggedInUserType = dataService.getUserType();
      if (params.type != loggedInUserType) {
        this.dataService.showMessage('Error en la carga de inicio', '', 'error');
      } else {
        this.userType = loggedInUserType;
        this.loadData(this.userType);
      }
    });
    let localId = this.dataService.getUserId();
    if (this.userType == "user") {
      this.dataService.getSeller(parseInt(localId)).subscribe(responseSeller => {
        if (!responseSeller[0]) {
          Swal.close();
          //this.dataService.showMessage("Error inesperado", "Ha ocurrido un error inesperado en la base de datos.", "error");
          return;
        } else if (!Array.isArray(responseSeller[1]) || responseSeller[1].length < 1) {
          Swal.close();
          //this.dataService.showMessage("Error inesperado", "Error en la carga de datos del usuario.", "error");
          return;
        }
        this.dataService.insertLog(localId, null, null, "Inicio de sesión exitoso", 
          responseSeller[1][0].seller_name, responseSeller[1][0].seller_lastname,responseSeller[1][0].moderator_institution).subscribe(responseLog=>{
          console.log(responseLog);
        });
      });
    }else if(this.userType == "mod"){
      this.dataService.getModerator(parseInt(this.dataService.getUserId())).subscribe(responseModerator => {
        console.log(responseModerator);
        this.dataService.insertLog(null, this.dataService.getUserId(), null, "Inicio de sesión exitoso", 
        responseModerator[1][0].moderator_name, responseModerator[1][0].moderator_lastname,responseModerator[1][0].moderator_institution).subscribe(responseLog=>{
            console.log(responseLog);
        });
      });
    }else{
      this.dataService.insertLog(null, null, localId, "Inicio de sesión exitoso").subscribe(responseLog=>{
        console.log(responseLog);
      });
    }
    
  }

  minDate = new Date();

  ngOnInit(): void {
  }

  private loadData(userType : string) {
    switch (userType) {
      case this.typeUser: {
        this.loadUserData();
        break;
      }
      case this.typeMod: {
        this.loadModData();
        break;
      }
      case this.typeAdmin: {
        this.loadAdminData();
        break;
      }
      default: {
        break;
      }
    }
  }

  private loadUserData() {
    this.dataService.getSeller(parseFloat(this.dataService.getUserId()))
      .subscribe(responseUserData => {

      if (!responseUserData[0]) {
        //console.error(responseUserData[1]);
        //this.dataService.showMessage('Error inesperado', 'Ha ocurrido un error inesperado en la base de datos.', 'error');
        return;
      }

      this.seller = responseUserData[1];
    });

    //console.log(this.dataService.getUserId());


    this.dataService.getProfit(parseFloat(this.dataService.getUserId()))
      .subscribe(responseProfit => {

      if (!responseProfit[0]) {
        //console.error(responseProfit[1]);
        //this.dataService.showMessage('Error inesperado', 'Ha ocurrido un error inesperado en la base de datos.', 'error');
        return;
      }

      this.user_profit = responseProfit[1];
      //console.log(this.user_profit);
    });

    //get grade
    this.dataService.getSeller(parseInt(this.dataService.getUserId())).subscribe(response => {
      //console.log(response);

      if (!response[0]) {
        Swal.close();
        //this.dataService.showMessage("Error inesperado", "Ha ocurrido un error inesperado en la base de datos.", "error");
        return;
      } else if (!Array.isArray(response[1]) || response[1].length < 1) {
        Swal.close();
        //this.dataService.showMessage("Error inesperado", "Error en la carga de datos del usuario.", "error");
        return;
      }

      if (response[1].length >= 1) {
        
        //console.log("seller",response[1]);
        this.dataService.getGradeId(response[1][0].grade_id).subscribe(responseGrade =>{
          //console.log("Grade:", responseGrade);
          if (!responseGrade[0]) {
            Swal.close();
            this.dataService.showMessage("Error inesperado", "Ha ocurrido un error inesperado en la base de datos.", "error");
            return;
          } else if (!Array.isArray(responseGrade[1]) || responseGrade[1].length < 1) {
            Swal.close();
            this.dataService.showMessage("Error inesperado", "Error en la carga de datos del usuario.", "error");
            return;
          }
          this.dataService.getModerator(parseInt(responseGrade[1][0].moderator_id)).subscribe(responseModerator =>{

            if (Array.isArray(responseModerator[1])) {
              //console.log(responseModerator);
              if (responseModerator[1][0].moderator_date != null) {
                this.date = responseModerator[1][0].moderator_date;
                //this.date = new Date(response[1][0].moderator_date);
                //this.date.setDate(this.date.getDate() + 1);
              }
            }
          });
        });
      }

    });
  }

  private loadModData() {
    const modId = this.dataService.getUserId();
    this.dataService.getRequestByModerator(parseInt(modId, 10)).subscribe(responseRequestSellers =>{
      this.dataService.getModerator(parseInt(this.dataService.getUserId())).subscribe(response =>{

        if (Array.isArray(response[1])) {
          console.log(response[1][0].moderator_date);
          if (response[1][0].moderator_date != null) {
            this.date = new Date(response[1][0].moderator_date);
            //this.date = new DatePipe('es-ES').transform(response[1][0].moderator_date, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'", 'Europe/Madrid');
            //this.date.setDate(this.date.getDate()+1);
            console.log(this.date)
            //this.date.setDate(this.date.getDate());
          }
          
        }
      });

      if (!responseRequestSellers[0]) {
        //console.error(responseRequestSellers[1]);
        this.dataService.showMessage('Error inesperado', 'Ha ocurrido un error inesperado en la base de datos.', 'error');
        return;
      }

      if (responseRequestSellers[1] == "no result") {
      } else {
        this.requestModList = responseRequestSellers[1];
        this.requestModList.splice(10);
        this.requestModList.forEach((request, index) => {
          this.dataService.getGradeBySellerId(request.seller_id).subscribe(response => {
            if (!response[0]) {
              console.error(response[1]);
              Swal.close();
              this.dataService.showMessage('Error inesperado', 'Error inesperado en la base de datos.', 'error');
              return;
            }
            this.requestModList[index]['grade_name'] = response[1][0]['grade_name'];

          });
        });
      }

    });
  }
  updateDate(){
    this.date.setDate(this.date.getDate()+1);

    this.dataService.updateModeratorDate(parseInt(this.dataService.getUserId()), this.date.toISOString().substring(0, 10)).subscribe(res =>{
      console.log(res);
      if(res[0]== true){
        this.dataService.showMessage('Fecha de tramitación modificada correctamente', '', 'success');
        
        this.dataService.getModerator(parseInt(this.dataService.getUserId())).subscribe(responseModerator => {
          console.log(responseModerator);
          this.dataService.insertLog(null, this.dataService.getUserId(), null, "Fecha de tramitación modificada correctamente", 
          responseModerator[1][0].moderator_name, responseModerator[1][0].moderator_lastname,responseModerator[1][0].moderator_institution).subscribe(responseLog=>{
              console.log(responseLog);
          });
        });
      }else{
        this.dataService.showMessage('No se pudo modificar la fecha de tramitación de pedidos', '', 'error');
      }
    });
  }

  loadInstitutions() {
    this.dataService.getAllModerators().subscribe(response => {
      if (!response[0]) {
        //console.error(response[1]);
        Swal.close();
        this.dataService.showMessage('Error inesperado', 'Error inesperado en la base de datos.', 'error');
        return;
      }

      this.moderators = response[1];
      this.loadOrders();
    });
  }

  public loadOrders() : void {
    this.dataService.getAllOrders().subscribe(response => {
      Swal.close();
      if (!response[0]) {
        //console.error(response[1]);
        this.dataService.showMessage('Error inesperado', 'Error inesperado en la base de datos.', 'error');
        return;
      }

      if (response[1] == "no result") {
      } else {
        this.orderAdminList = response[1];
        this.orderAdminList.splice(10);
        this.orderAdminList.forEach((order, index) => {
          this.loadGradeData(index);
        });
      }
    });
  }

  public loadGradeData(index : number) : void {
    this.dataService.getGradeId(this.orderAdminList[index].grade_id).subscribe(response => {
      if (!response[0]) {
        //console.error(response[1]);
        Swal.close();
        this.dataService.showMessage('Error inesperado', 'Error inesperado en la base de datos.', 'error');
        return;
      }


      if (Array.isArray(response[1]) && response[1].length > 0) {
        //console.log(response);
        this.orderAdminList[index].orders_grade = response[1][0].grade_name;
        const moderator = this.moderators.filter(item => item.moderator_id == response[1][0].moderator_id)[0];
        this.orderAdminList[index].orders_institution = moderator.moderator_institution;
      }

    });
  }

  private loadAdminData() {
    this.loadInstitutions();
    // this.dataService.getAllOrders().subscribe(responseOrdersAdmin =>{
    //
    //   if (!responseOrdersAdmin[0]) {
    //     console.error(responseOrdersAdmin[1]);
    //     this.dataService.showMessage('Error inesperado', 'Ha ocurrido un error inesperado en la base de datos.', 'error');
    //     return;
    //   }
    //
    //   if (responseOrdersAdmin[1] == "no result") {
    //   } else {
    //     this.orderAdminList = responseOrdersAdmin[1].reverse();
    //     this.orderAdminList.splice(5);
    //   }
    // });

    this.dataService.getAllProducts().subscribe(responseAdminProducts =>{
      //console.log(responseAdminProducts);
      if (!responseAdminProducts[0]) {
        //console.error(responseAdminProducts[1]);
        this.dataService.showMessage('Error inesperado', 'Ha ocurrido un error inesperado en la base de datos.', 'error');
        return;
      }

      if (responseAdminProducts[1] == "no result") {
      } else {
        this.productAdminList = responseAdminProducts[1];
        this.productAdminList = this.productAdminList.map(item => {
          item.product_quantity = +item.product_quantity;
          item.product_treshold = +item.product_treshold;

          return item;
        });

        this.productAdminList = this.productAdminList.filter(productAdminList => productAdminList.product_quantity <= productAdminList.product_treshold);
      }
    });
  }

}
