<div class="text-center">
  <mat-form-field class="w-25 m-2" *ngIf="showSearchInput && productList.length > 0">
    <mat-label>Buscar producto</mat-label>
    <input matInput (keyup)="filter($event)">
  </mat-form-field>
  <h2 *ngIf="productList.length < 1">No hay productos cargados</h2>
  <table mat-table *ngIf="productList.length > 0" [dataSource]="productDataSource" matSort>

    <ng-container matColumnDef="product_name" sticky  [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true">
      <th mat-header-cell *matHeaderCellDef  [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> Producto </th>
      <td mat-cell (mouseover)="showImage($event, product)"
        (mousemove)="relocateImage($event, product)" (mouseout)="hideImage($event, product)"
        *matCellDef="let product" class="text-left"  [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> 
        {{ product.product_name }} 
      </td>
        <ng-container *ngIf="tableConfig == CART_CONFIG">
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
    </ng-container>

    <ng-container matColumnDef="product_saleprice"  >
      <th mat-header-cell *matHeaderCellDef class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> Precio costo </th>
      <td mat-cell *matCellDef="let product"> {{ product.product_saleprice | currency : "EUR" : "symbol" }} </td>
      <ng-container *ngIf="tableConfig == CART_CONFIG">
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
    </ng-container>

    <ng-container matColumnDef="product_sellerprice">
      <th mat-header-cell *matHeaderCellDef class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> Precio de venta </th>
      <td mat-cell *matCellDef="let product" > {{ product.product_sellerprice | currency : "EUR" : "symbol" }} </td>
      <ng-container *ngIf="tableConfig == CART_CONFIG">
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
    </ng-container>

    <ng-container matColumnDef="product_treshold">
      <th mat-header-cell *matHeaderCellDef [class.text-center]="paddingColumnsResponsive == true" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> Alerta de stock</th>
      <td mat-cell *matCellDef="let product" [class.text-center]="paddingColumnsResponsive == true" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> {{ product.product_treshold }} </td>
      <ng-container *ngIf="tableConfig == CART_CONFIG">
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
    </ng-container>

    <ng-container matColumnDef="product_profit">
      <th mat-header-cell *matHeaderCellDef class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> Beneficio por unidad </th>
      <td mat-cell *matCellDef="let product" > {{ product.product_sellerprice - product.product_saleprice | currency : "EUR" : "symbol" }} </td>
      <ng-container *ngIf="tableConfig == CART_CONFIG">
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
    </ng-container>

    <ng-container matColumnDef="product_total_sale">
      <th mat-header-cell *matHeaderCellDef class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> Total a pagar </th>
      <td mat-cell *matCellDef="let product; let i = index" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> {{ (product.product_saleprice * quantityList[i]) | currency : "EUR" : "symbol" }} </td>
      <ng-container *ngIf="tableConfig == CART_CONFIG">
        <td mat-footer-cell *matFooterCellDef class="text-bold" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> {{ getTotalSalePrice() | currency : "EUR" : "symbol" }} </td>
      </ng-container>
    </ng-container>

    <ng-container matColumnDef="product_total_seller">
      <th mat-header-cell *matHeaderCellDef> Total precio venta </th>
      <td mat-cell *matCellDef="let product; let i = index" > {{ (product.product_sellerprice * quantityList[i]) | currency : "EUR" : "symbol" }} </td>
      <ng-container *ngIf="tableConfig == CART_CONFIG">
        <td mat-footer-cell *matFooterCellDef class="text-bold"> {{ getTotalSalePrice() | currency : "EUR" : "symbol" }} </td>
      </ng-container>
    </ng-container>

    <ng-container matColumnDef="product_total_profit">
      <th mat-header-cell *matHeaderCellDef class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> Total beneficio </th>
      <td mat-cell *matCellDef="let product; let i = index" > {{ ((product.product_sellerprice - product.product_saleprice) * quantityList[i]) | currency : "EUR" : "symbol" }} </td>
      <ng-container *ngIf="tableConfig == CART_CONFIG">
        <td mat-footer-cell *matFooterCellDef class="text-bold"> {{ getTotalProfit() | currency : "EUR" : "symbol" }} </td>
      </ng-container>
    </ng-container>

    <ng-container matColumnDef="product_stock">
      <th mat-header-cell *matHeaderCellDef [class.text-center]="paddingColumnsResponsive == true" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> Stock </th>
      <td mat-cell *matCellDef="let product" [class.text-center]="paddingColumnsResponsive == true" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> {{ product.product_quantity }} </td>
      <ng-container *ngIf="tableConfig == CART_CONFIG">
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
    </ng-container>
    
    <ng-container matColumnDef="product_quantity"  >
      <th mat-header-cell *matHeaderCellDef class="text-center">Cantidad</th>
      <td mat-cell *matCellDef="let product; let i = index" >
        <button (click)="substractQuantity(i)"
          *ngIf="tableConfig == CART_CONFIG && quantityList[i] > 1 " mat-mini-fab
          class="mr-2"
          color="primary"><mat-icon>remove</mat-icon></button>
       <mat-form-field *ngIf="showQuantityInput" class="cantidad text-center">
          <input type="number" required min="1" max="product.product_quantity" onkeypress='return event.charCode >= 49 && event.charCode <= 57 || event.keyCode == 8 || event.keyCode == 46'
            [(ngModel)]="quantityList[i]" matInput  placeholder="Cantidad"
            (keyup)="keyboardChangeQuantity($event, i, product)">
        </mat-form-field>
        
        <button (click)="addQuantity(i)"
          *ngIf="tableConfig == CART_CONFIG" mat-mini-fab
          class="ml-2"
          color="primary"><mat-icon>add</mat-icon></button>
        {{ showQuantityText ? quantityList[i] : '' }}
       </td>
       <ng-container *ngIf="tableConfig == CART_CONFIG">
         <td mat-footer-cell *matFooterCellDef class="text-bold"> Total unidades: {{ getTotalQuantity() }} </td>
       </ng-container>
    </ng-container>


    <ng-container matColumnDef="action" class="text-center-custom">
      <th mat-header-cell *matHeaderCellDef class="text-center-custom" 
        [class.text-center]="paddingColumnsResponsive == true" 
        [class.pr-3]="paddingColumnsResponsive == true" 
        [class.pl-3]="paddingColumnsResponsive == true"> 
       Opciones
      </th>
      <td mat-cell *matCellDef="let product" 
      [class.text-center]="paddingColumnsResponsive == true" 
      [class.pr-3]="paddingColumnsResponsive == true" 
      [class.pl-3]="paddingColumnsResponsive == true">
        <button (click)="addProductTrigger(product)" mat-raised-button
          color="primary" *ngIf="showAddButton" [disabled]="product.product_quantity < 1">
          {{ product.product_quantity < 1 ? 'Sin stock' : 'Agregar' }}
          <mat-icon>shopping_cart</mat-icon>
        </button>
        <!-- Cambiar ruta del boton de modificar -->
        <button [routerLink]="['/' +ADMIN_CONFIG,'product-detail',product.product_name]" mat-raised-button color="primary" class="mr-2" *ngIf="showModifyButton" >Modificar</button>
        <button (click)="deleteProductTrigger(product)" mat-raised-button color="warn" class="mr-2" *ngIf="showDeleteProductButton">Eliminar</button>
        <button (click)="deleteFromCartTrigger(product)" mat-raised-button color="warn" class="mr-2" *ngIf="showDeleteFromCartButton"><mat-icon>delete</mat-icon></button>
      </td>
      <ng-container *ngIf="tableConfig == CART_CONFIG">
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true">
    </tr>

    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
              [class.red-row]="tableConfig == DEFAULT_CONFIG && row.product_quantity <= 0">
    </tr>

    <tr mat-row *matRowDef="let row; columns: ['groupHeader']; when: isGroup">
    </tr>

    <ng-container matColumnDef="groupHeader">
      <td colspan="999" mat-cell *matCellDef="let category"><strong>{{category.category_name}}</strong></td>
    </ng-container>

    <ng-container *ngIf="tableConfig == CART_CONFIG">
      <tr mat-footer-row *matFooterRowDef="displayedColumns">
      </tr>
    </ng-container>

  </table>

  <!-- <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator> -->
  <button *ngIf="tableConfig == CART_CONFIG && productList.length > 0"
          mat-raised-button
          color="primary"
          class="m-2"
          [disabled]="disableButtons"
          (click)="saveOrderTrigger()">
    {{ previousOrder != null ? 'Guardar Cambios' : 'Guardar Cambios'}}
  </button>

  <button *ngIf="tableConfig == CART_CONFIG && previousOrder != null &&
          productList.length > 0 && !previousTramited"
          (click)="cancelOrderTrigger()"
          mat-raised-button
          color="warn"
          [disabled]="disableButtons"
          class="m-2">Cancelar pedido</button>
          
          <mat-card class="mat-elevation-z2 p-2 mt-2 warning text-center" *ngIf="tableConfig == CART_CONFIG && productList.length > 0">
            <mat-icon class="align-middle">error</mat-icon>
            <span class="align-middle">
              <strong>POR FAVOR, <span style="text-decoration: underline; font-size: 14pt;">GUARDA</span> EL PEDIDO ANTES DE SALIR DE LA PÁGINA, LUEGO PODRÁS MODIFICARLO TANTAS VECES COMO QUIERAS.</strong>
            </span>

          </mat-card>

          <mat-card class="mat-elevation-z2 p-2 mt-2 mb-2 danger text-center"
          *ngIf="tableConfig == CART_CONFIG && previousOrder != null &&
          productList.length > 0 && !previousTramited">
  <mat-icon class="align-middle">warning</mat-icon>
  <span class="align-middle">
    PRECAUCION, AL PRESIONAR EL BOTON "CANCELAR PEDIDO", EL PEDIDO QUEDARA CANCELADO DE FORMA IRREVERSIBLE
  </span>

</mat-card>

<mat-card class="mat-elevation-z2 p-2 mt-2 mb-2 text-center"
*ngIf="tableConfig == CART_CONFIG && previousOrder != null &&
productList.length == 0 && !previousTramited">
  <mat-icon class="align-middle">warning</mat-icon>
  <span class="align-middle">
    <h4>
      <strong><U>POR FAVOR, CONFIRME EL VACIADO COMPLETO DEL CARRITO</U></strong>
      </h4></span>
</mat-card>

<button *ngIf="tableConfig == CART_CONFIG && previousOrder != null &&
  productList.length == 0 && !previousTramited"
  (click)="cancelOrderTrigger()"
  mat-raised-button
  color="warn"
  [disabled]="disableButtons"
  class="m-2">Confirmar Vaciado</button>

  <button *ngIf="tableConfig == CART_CONFIG && previousOrder != null &&
  productList.length == 0 && !previousTramited"
  onclick="location.reload()"
  mat-raised-button
  color="warn"
  [disabled]="disableButtons"
  class="m-2">Volver Atrás</button>



</div>

<div id="productImage">
</div>
