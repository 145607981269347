import { Component, OnInit, Input, ViewChild, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DataMasterService } from 'src/app/services/data-master.service';
import { Order } from 'src/app/classes/order.class';


@Component({
  selector: 'app-table-sales',
  templateUrl: './table-sales.component.html',
  styleUrls: ['./table-sales.component.scss']
})
export class TableSalesComponent implements OnInit {

  @Input() userType : string;
  @Input() orderList : Order[] = [];
  @Input() order:string;
  @Input() orderM:string;
  @Input() showMark : boolean = false;
  @Input() showGeneratePDF : boolean = false;
  @Input() showInstitute : boolean = false;
  @Input() showGrade : boolean = false;
  displayedColumns = ['orders_date', 'orders_totalprofit', 'orders_total', 'orders_total_profit','action'];

  @Output() readedEvent : EventEmitter<Order> = new EventEmitter<Order>();

  orderDataSource: MatTableDataSource<Order>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  paddingColumnsResponsive: boolean;

  constructor(public dataService : DataMasterService ) {
    this.orderDataSource = new MatTableDataSource(this.orderList);
    console.log(this.orderList);
  }

  ngOnChanges(changes: SimpleChanges) : void {
    if (changes['showGrade'] != null && this.showGrade) {
      this.displayedColumns = ["orders_grade"].concat(this.displayedColumns);
    }

    if (changes['showInstitute'] != null && this.showInstitute) {
      this.displayedColumns = ["orders_institution"].concat(this.displayedColumns);
      this.displayedColumns = ["orders_id"].concat(this.displayedColumns);
    }


    if (changes['orderList'] != null) {
      this.orderDataSource.data = this.orderList;
      this.orderDataSource.paginator = this.paginator;
      this.orderDataSource.sort = this.sort;
    }

  }
  ngOnInit(): void {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.paddingColumnsResponsive = true; 
    }else{
      this.paddingColumnsResponsive = false; 
    }
  }

  toggleRead(order : Order) {
    order.orders_readed = order.orders_readed == '1' ? '0' : '1';
    this.readedEvent.emit(order);
  }

  generatePDF(orderId) {
    let localId = this.dataService.getUserId();
    this.dataService.insertLog(null, null, localId, "Pdf generado de pedido: " + orderId).subscribe(responseLog=>{
      console.log(responseLog);
    });
    const base_url = this.dataService.url_base;
    window
    .open(`${base_url}/services/generaloperations/generate_pdf.php?orders_id=${orderId}`, '_blank');
  }

  addCheck(element,order){
    
    if (element.check == false) {
      element.check = true;
    }else{
      element.check = false;
    }
  }

  generatePdfDinamic(order){
    console.log(order);
    let categoriesCheck = [];
    const base_url = this.dataService.url_base;
    //check categories
    for (let i = 0; i < order.categories.length; i++) {
      if(order.categories[i].check == true) {
        categoriesCheck.push(order.categories[i].category_name)
      }
    }
    //pdf general
    let localId = this.dataService.getUserId();
    this.dataService.insertLog(null, null, localId, "Pdf generado de pedido: " + order.orders_id).subscribe(responseLog=>{
      console.log(responseLog);
    });
    if (categoriesCheck.length == 0) {
      
      window
      .open(`${base_url}/services/generaloperations/generate_pdf.php?orders_id=${order.orders_id}`, '_blank');
    }else{
      window
      .open(`${base_url}/services/generaloperations/generate_pdf_dynamic.php?orders_id=${order.orders_id}&categories=${categoriesCheck}`, '_blank');
      /*this.dataService.generatePdf(order.orders_id,categoriesCheck).subscribe((response : any) =>{
        console.log(response);
        
      });*/
    }
    
  }

}
