import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup } from '@angular/forms';

import { DataMasterService } from 'src/app/services/data-master.service';

import { Moderator } from 'src/app/classes/moderator.class';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-mod-detail',
  templateUrl: './mod-detail.component.html',
  styleUrls: ['./mod-detail.component.scss']
})
export class ModDetailComponent implements OnInit {
  moderator_id : number;
  moderator_name : string;
  moderator_password : string;
  repeat_password : string;
  moderator_lastname : string;
  moderator_email : string;
  moderator_phonenumber : string;
  moderator_institution : string;
  moderator_location : string;
  moderator_overprice : number;
  moderator_saleprice : number;

  @ViewChild('modForm') modForm : FormGroup;

  constructor(public dataService : DataMasterService, private activatedRoute : ActivatedRoute,
    private router : Router) {

    this.activatedRoute.params.subscribe(params =>{
      this.moderator_id = params.id;

      this.dataService.getModerator(this.moderator_id).subscribe(response =>{
        if (!response[0]) {
          console.error(response[1]);
          Swal.close();
          this.dataService.showMessage('Error inesperado', 'Error inesperado en la base de datos.', 'error');
          return;
        } else if (!Array.isArray(response[1])) {
          console.error(response);
          Swal.close();
          this.dataService.showMessage('Error inesperado', 'Error inesperado al obtener datos.', 'error');
          return;
        }

        const moderator = response[1][0];
        console.log(moderator)

        this.moderator_name = moderator.moderator_name;
        this.moderator_lastname = moderator.moderator_lastname;
        this.moderator_email = moderator.moderator_email;
        this.moderator_phonenumber = moderator.moderator_phonenumber;
        this.moderator_institution = moderator.moderator_institution;
        this.moderator_location = moderator.moderator_location;
        this.moderator_overprice = +moderator.moderator_overprice;
        this.moderator_saleprice = +moderator.moderator_saleprice;
      });
    });
  }

  ngOnInit(): void {
  }

  updateModerator() {
    if (this.modForm.invalid) {
      this.dataService.showMessage('Campos invalidos', 'Por favor, rellene de forma valida los campos marcados.', 'error');
      return;
    }

    let password = null;
    if (this.moderator_password != null) {
      if (this.moderator_password != this.repeat_password) {
        this.dataService.showMessage('Las contraseñas no coinciden', '', 'error');
        return;
      } else if (this.moderator_password.length > 0 && this.moderator_password == this.repeat_password) {
        password = this.moderator_password;
      }
    }

    this.dataService.updateModerator(this.moderator_id, this.moderator_name,
      this.moderator_lastname, this.moderator_email, this.moderator_phonenumber,
      this.moderator_overprice, this.moderator_institution, this.moderator_location,this.moderator_saleprice, password).subscribe(res =>{
      console.log(res);
      if(res[0]== true){
        this.dataService.showMessage('Coordinador modificado', '', 'success');
        this.router.navigate(['/admin', 'admin-moderators']);
      }else{
        this.dataService.showMessage('No se pudo modificar el coordinador', '', 'error');
      }
    });
  }

}
