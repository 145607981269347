import { Component, OnInit } from '@angular/core';
import { DataMasterService } from 'src/app/services/data-master.service';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
@Component({
  selector: 'app-excel-dump',
  templateUrl: './excel-dump.component.html',
  styleUrls: ['./excel-dump.component.scss']
})
export class ExcelDumpComponent implements OnInit {

  file:File;
  arrayBuffer:any;
  filelist : any;
  productsNotFount = "";
  excelList : any[] = [];

  constructor(private dataService : DataMasterService) { 
    this.getExcel();
  }

  ngOnInit(): void {
  }

  getExcel(){
    this.dataService.getAllExcel().subscribe(response =>{
      console.log(response);
      if (response[1] == 'no result') {
        this.excelList = [];
      }else{
        this.excelList = response[1];
      }
    });
  }

  addfile(event){    
    this.file= event.target.files[0];     
    let fileReader = new FileReader();    
    fileReader.readAsArrayBuffer(this.file);
    let productExist : any[] = [];  
    Swal.showLoading();
    fileReader.onload = (e) => {    
      this.arrayBuffer = fileReader.result;    
      var data = new Uint8Array(this.arrayBuffer);    
      var arr = new Array();    
      for(var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);    
      var bstr = arr.join("");    
      var workbook = XLSX.read(bstr, {type:"binary"});
      
      var sheet_name = workbook.SheetNames[2];    
      var worksheet = workbook.Sheets["ALUMNOS"];    
      console.log(XLSX.utils.sheet_to_json(worksheet,{raw:true}));  

      //order resume
      var resumeOrder = XLSX.utils.sheet_to_json(worksheet,{raw:true});
      let arrayOrder : any[] = [];
      for (let i = 0; i < resumeOrder.length; i++) {
        arrayOrder.push(resumeOrder[i]);
      }
      //data resume
      let institute = arrayOrder[1].__EMPTY_3; 
      let incharge = arrayOrder[2].__EMPTY_3;
      let phone_number = arrayOrder[2].__EMPTY_5;
      let course = arrayOrder[3].__EMPTY_3;
      let email = arrayOrder[3].__EMPTY_5;
      //obtain resume total
      var worksheet_generate = workbook.Sheets["PRESUPUESTO TOTAL"]; 
      //console.log("presupuesto");
      //console.log(worksheet_generate);
      let total_generate_array = [];
      total_generate_array.push(XLSX.utils.sheet_to_json(worksheet_generate,{raw:true})[1]);
      total_generate_array.push(XLSX.utils.sheet_to_json(worksheet_generate,{raw:true})[2]);
      total_generate_array.push(XLSX.utils.sheet_to_json(worksheet_generate,{raw:true})[4]);
      //console.log("presupuesto 2");
      //console.log(total_generate_array);
      let total_quantity = total_generate_array[1].__EMPTY_1;
      let total_generate = total_generate_array[2].__EMPTY_2;
      let total_to_pay = total_generate_array[1].__EMPTY_2 - total_generate_array[1].__EMPTY_3;
      let total_income = total_generate_array[1].__EMPTY_2;
      let total_benefit = total_generate_array[1].__EMPTY_3;

      let date = new Date();
      let actual_date = date.getFullYear() + '/' + (date.getMonth() + 1) + '/' + date.getDate();
      //obtain sellers
      let sellers : any[] = [];
      for (let j = 5; j < arrayOrder.length; j++) {
        if (arrayOrder[j].__EMPTY_3 != null) {
          let seller = {
            id:arrayOrder[j].__EMPTY_2,
            id_sheet:'A.'+arrayOrder[j].__EMPTY_2,
            name: arrayOrder[j].__EMPTY_3,
            total_pay: arrayOrder[j].__EMPTY_4,
            income: arrayOrder[j].__EMPTY_5,
            benefit: arrayOrder[j].__EMPTY_6
          }
          sellers.push(seller);
        }
      }
      //console.log(sellers)
      //order sellers
      let totalProductCount = 0;
      let checkTotalProduct = 0;
      for (let i = 0; i < sellers.length; i++) {
        //seller sheet
        var worksheet = workbook.Sheets[sellers[i].id_sheet];    
        let ordersSeller = XLSX.utils.sheet_to_json(worksheet,{raw:true});
        //console.log(ordersSeller);
        let orderSellerFilter = [];
        for (let j = 0; j < ordersSeller.length; j++) {
          orderSellerFilter.push(ordersSeller[j]);
        }
        //data seller filter
        /*if (orderSellerFilter[0].PROVIAJE != null) {
          sellers[i].fax = orderSellerFilter[0].PROVIAJE;
        }else{
          sellers[i].fax = "Sin Fax"
        }
        sellers[i].phone_number = orderSellerFilter[1].PROVIAJE;
        sellers[i].email = orderSellerFilter[2].PROVIAJE;*/
        //change
        sellers[i].order = [];
        sellers[i].total_quantity = orderSellerFilter[2].__EMPTY_2;
        for (let k = 4; k < orderSellerFilter.length; k++) {
          if (orderSellerFilter[k].__EMPTY_2 != null && orderSellerFilter[k].__EMPTY_1 != "TOTALES") {
            let product = {
              id:orderSellerFilter[k].__EMPTY,
              name: orderSellerFilter[k].__EMPTY_1,
              quantity: orderSellerFilter[k].__EMPTY_2,
              cost_price: orderSellerFilter[k].__EMPTY_3,
              sale_price: orderSellerFilter[k].__EMPTY_4,
              total_to_pay: orderSellerFilter[k].__EMPTY_5,
              total_sale: orderSellerFilter[k].PROVIAJE,
              student_raised: orderSellerFilter[k].__EMPTY_6,
            }
            sellers[i].order.push(product);
            totalProductCount += 1;
            this.dataService.searchProductName(product.name).subscribe(responseProduct =>{
              //console.log(responseProduct[1]);
              checkTotalProduct += 1;
              if (responseProduct[1] == "no result") {
                if (this.productsNotFount.length == 0) {
                  this.productsNotFount = this.productsNotFount + product.name;
                  //console.log(this.productsNotFount);
                }else{
                  if (this.productsNotFount.toLowerCase().search(product.name.toLowerCase()) != -1) {
                    //nada
                  }else{
                    this.productsNotFount = this.productsNotFount + ',' + product.name;
                  }
                }
              }else{
                //nom permitir mas de las unidades que hay
                //console.log(responseProduct);
                if (productExist.length > 0) {
                  var breakSearch = false; 
                  for (let i = 0; i < productExist.length; i++) {
                    if (productExist[i].product_name.toLowerCase().search(product.name.toLowerCase()) != -1) {
                      //console.log("substract quantity");
                      productExist[i].product_quantity -= product.quantity;
                      breakSearch = true;
                    }
                  }
                  if (breakSearch == false) {
                    responseProduct[1][0].product_quantity -= product.quantity;
                    productExist.push(responseProduct[1][0]);
                  }
                }else{
                  productExist.push(responseProduct[1][0]);
                  if (productExist[0].product_name.toLowerCase().search(product.name.toLowerCase()) != -1) {
                    productExist[0].product_quantity -= product.quantity;
                  }
                }
              }
              //validate stop search product
              if (totalProductCount == checkTotalProduct) {
                //console.log(productExist);
                let validateStock = true;
                let productName = "";
                for (let i = 0; i < productExist.length; i++) {
                  if (productExist[i].product_quantity < 0) {
                    validateStock = false;
                    if (productName.length == 0) {
                      productName = productName + productExist[i].product_name;
                    }else{
                      productName = productName + ',' + product.name;
                    }
                  }
                }
                if (validateStock == false) {
                  Swal.fire({
                    icon : 'error',
                    title: 'Los siguientes productos no poseen el stock necesario para generar el volcado favor modifique el excel',
                    text : productName
                  });
                }else{
                  if (this.productsNotFount.length > 0) {
                    Swal.fire({
                      icon: 'info',
                      title: 'Los siguientes productos no estan registrados en la base de datos, y no se descontara su stock',
                      text: this.productsNotFount,
                      showCancelButton: true,
                      confirmButtonText: 'Confirmar',
                      cancelButtonText: 'Cancelar'
                    }).then((result) => {
                      if (result.isConfirmed) {
                        Swal.fire('Se registro correctamente el excel en la base de datos', '', 'success');
                        //insert excel
                        this.dataService.insertExcel(institute, incharge, course, String(total_to_pay), total_income, total_benefit, total_quantity, total_generate, JSON.stringify(sellers)).subscribe(response =>{
                          //console.log(response);
                          let endUpdateProducts = 0;
                          if (productExist.length > 0) {
                            for (let i = 0; i < productExist.length; i++) {
                              this.dataService.updateStockProduct(productExist[i].product_id,productExist[i].product_quantity).subscribe(responseProduct=>{
                                endUpdateProducts += 1;
                                if (endUpdateProducts == productExist.length) {
                                  const base_url = this.dataService.url_base;
                                  /*window
                                  .open(`${base_url}/services/generaloperations/excel_dump.php?sellers=${JSON.stringify(sellers)}
                                  &institute=${institute}&incharge=${incharge}&course=${course}&email=${email}&phone_number=${phone_number}&total_quantity=${total_quantity}
                                  &total_to_pay=${total_to_pay}&total_generate=${total_generate}&date=${actual_date}&id_excel=${response[1]}`,
                                  '_blank');*/
                                  window.location.reload();
                                }
                              });
                            }
                          }else{
                            const base_url = this.dataService.url_base;
                            /*window
                            .open(`${base_url}/services/generaloperations/excel_dump.php?sellers=${JSON.stringify(sellers)}
                            &institute=${institute}&incharge=${incharge}&course=${course}&email=${email}&phone_number=${phone_number}&total_quantity=${total_quantity}
                            &total_to_pay=${total_to_pay}&total_generate=${total_generate}&date=${actual_date}&id_excel=${response[1]}`,
                            '_blank');*/
                            window.location.reload();
                          }
                          
                        });
                      }
                    })
                  }else{
                    Swal.fire({
                      icon: 'success',
                      title: 'No se detectaron errores en el excel desea continuar con el proceso ?',
                      text: this.productsNotFount,
                      showCancelButton: true,
                      confirmButtonText: 'Confirmar',
                      cancelButtonText: 'Cancelar'
                    }).then((result) => {
                      if (result.isConfirmed) {
                        Swal.fire('Se registro correctamente el excel en la base de datos', '', 'success');
                        let endUpdateProducts = 0;
                        //insert excel
                        this.dataService.insertExcel(institute, incharge, course, String(total_to_pay), total_income, total_benefit, total_quantity, total_generate, JSON.stringify(sellers)).subscribe(response =>{
                          console.log(response);
                          let endUpdateProducts = 0;
                          for (let i = 0; i < productExist.length; i++) {
                            this.dataService.updateStockProduct(productExist[i].product_id,productExist[i].product_quantity).subscribe(responseProduct=>{
                              endUpdateProducts += 1;
                              if (endUpdateProducts == productExist.length) {
                                const base_url = this.dataService.url_base;
                                /*window
                                .open(`${base_url}/services/generaloperations/excel_dump.php?sellers=${JSON.stringify(sellers)}
                                &institute=${institute}&incharge=${incharge}&course=${course}&email=${email}&phone_number=${phone_number}&total_quantity=${total_quantity}
                                &total_to_pay=${total_to_pay}&total_generate=${total_generate}&date=${actual_date}&id_excel=${response[1]}`,
                                '_blank');*/
                                window.location.reload();
                              }
                            });
                          }
                        });
                      }
                    })
                  }
                }

                
                
              }
            });
          }
        }
        
      }

      console.log(sellers);
      
    
    }    
  } 

}
