import { Component, OnInit } from '@angular/core';
import { Product } from 'src/app/classes/product.class';
import { DataMasterService } from 'src/app/services/data-master.service';

@Component({
  selector: 'app-catalogue',
  templateUrl: './catalogue.component.html',
  styleUrls: ['./catalogue.component.scss']
})
export class CatalogueComponent implements OnInit {

  products : Product[] = [];
  actualYear = new Date().getFullYear();

  constructor(private dataService : DataMasterService ) {
    this.dataService.setSideNav(false);
    this.dataService.getAllProducts().subscribe(respuesta => {
      this.products = respuesta[1];
    });

  }

  ngOnInit(): void {
  }


}
