import { Component, OnInit, Input, ViewChild, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DataMasterService } from 'src/app/services/data-master.service';

@Component({
  selector: 'app-table-excel',
  templateUrl: './table-excel.component.html',
  styleUrls: ['./table-excel.component.scss']
})
export class TableExcelComponent implements OnInit {

  @Input() excelList : any[] = [];

  displayedColumns = ['id','institute', 'moderator', 'course', 'total_to_pay', 'income', 'benefit', 'date', 'actions'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  paddingColumnsResponsive: boolean;

  constructor(private dataService : DataMasterService) {
    this.dataSource = new MatTableDataSource(this.excelList);
  }

  ngOnChanges(changes: SimpleChanges) : void {
    console.log(this.excelList);

    if (changes['excelList'] != null) {
      this.dataSource.data = this.excelList;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }

  ngOnInit(): void {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.paddingColumnsResponsive = true; 
    }else{
      this.paddingColumnsResponsive = false; 
    }
  }
  generatePdf(excel){
    console.log(excel);
    let sellers = [];
    this.dataService.getSheets(excel.id).subscribe(response =>{
      
      sellers = response[1];
      let counter = 0;
      for (let j = 0; j < sellers.length; j++) {
        this.dataService.getSheetsProducts(sellers[j].id_bd).subscribe(response =>{
          counter += 1;
          let total_quantity = 0;
          for (let k = 0; k < response[1].length; k++) {
            total_quantity += parseInt(response[1][k].quantity);
          }
          //sellers[j].order = response[1];
          sellers[j].total_quantity = total_quantity;
          if (counter == sellers.length) {
            const base_url = this.dataService.url_base;
            window
            .open(`${base_url}/services/generaloperations/excel_dump_sql.php?sellers=${JSON.stringify(sellers)}
            &institute=${excel.institute}&incharge=${excel.moderator}&course=${excel.course}&total_quantity=${excel.quantity}
            &total_to_pay=${excel.total_to_pay}&total_generate=${excel.generate}&date=${excel.date}&id_excel=${excel.id}`,
            '_blank');
          }
        })
        
      }
      
      console.log(sellers);
      
    })
    
    
  }

}
