import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormControl  } from '@angular/forms';
import { DataMasterService } from 'src/app/services/data-master.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-admin-order-date',
  templateUrl: './admin-order-date.component.html',
  styleUrls: ['./admin-order-date.component.scss']
})
export class AdminOrderDateComponent implements OnInit {

  date;

  dateFormGroup : FormGroup;
  constructor( public  formBuilder : FormBuilder, public dataService : DataMasterService) { 
    this.loadContactData();
  }

  ngOnInit(): void {
    
  }

  loadContactData(){
    
    this.dataService.getModerator(parseInt(this.dataService.getUserId())).subscribe(response =>{

      if (Array.isArray(response[1])) {
        console.log(response);
        if (response[1][0].moderator_date != null) {
          this.date = new Date(response[1][0].moderator_date);
          this.date.setHours(0);
          this.date.setMinutes(0);
          this.date.setSeconds(0);
        }
        
      }
    });
  }

  updateDate(){
    this.dataService.updateModeratorDate(parseInt(this.dataService.getUserId()), this.date.toISOString().substring(0, 10)).subscribe(res =>{
      console.log(res);
      if(res[0]== true){
        this.dataService.showMessage('Fecha de tramitación modificada correctamente', '', 'success');
        this.dataService.insertLog(null, this.dataService.getUserId(), null, "Fecha de tramitación modificada correctamente: "+ this.dataService.getUserId()).subscribe(responseLog=>{
          console.log(responseLog);
        });
      }else{
        this.dataService.showMessage('No se pudo modificar la fecha de tramitación de pedidos', '', 'error');
      }
    });
  }

}
