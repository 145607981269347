import { Component, OnInit } from '@angular/core';
import { DataMasterService } from 'src/app/services/data-master.service';
import { Request } from '../../../classes/request.class';
import { Orderdetails } from '../../../classes/orderdetails.class';
import { Seller } from '../../../classes/seller.class';
import { Grade } from '../../../classes/grade.class';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-admin-orders',
  templateUrl: './admin-orders.component.html',
  styleUrls: ['./admin-orders.component.scss']
})
export class AdminOrdersComponent implements OnInit {

  disableButtons : boolean = false;

  courseFilter : number = 0;
  pending_request_list : Request[] = [];
  carRequest_list : Request[] = [];
  sellers : Request[] = [];
  grades : Grade[] = [];
  grade_id : string;

  constructor(private dataService : DataMasterService ) {
    this.dataService.setFooter("fixed");
  }

  loadGradeList() {
    this.dataService.getGrade(parseInt(this.dataService.getUserId(), 10)).subscribe(response => {

      if (!response[0]) {
        console.error(response[1]);
        Swal.close();
        this.dataService.showMessage('Error inesperado', 'Error inesperado en la base de datos.', 'error');
        return;
      }

      if (response[1] == "no result") {
        this.dataService.showMessage('No existen cursos actualmente, por favor registre un curso.', '', 'warning');
        this.grades = [];
      } else {
        this.grades = response[1];
      }
    });
  }

  ngOnInit(): void {
    this.loadGradeList();
  }

  addRequest(request : Request) {
    this.carRequest_list = this.carRequest_list.concat([request]);
    this.pending_request_list = this.pending_request_list.filter(item => item != request);
  }

  deleteFromCar(request : Request) {
    this.pending_request_list = this.pending_request_list.concat([request]);
    this.carRequest_list = this.carRequest_list.filter(item => item != request);
  }
  /*obtener request de curso*/
  getRequestid() {
    this.carRequest_list = []
    this.dataService.getRequestGradeId(this.grade_id).subscribe(response => {

      if (!response[0]) {
        console.error(response[1]);
        Swal.close();
        this.dataService.showMessage('Error inesperado', 'Error inesperado en la base de datos.', 'error');
        return;
      } else if (!Array.isArray(response[1])) {
        this.dataService.showMessage('El curso seleccionado no tiene pedidos pendientes', '', 'info');
        return;
      }

      this.pending_request_list = response[1];
      this.pending_request_list = this.pending_request_list.filter(pending_request_list => pending_request_list.request_date == null);

      for (let i in this.pending_request_list) {
        this.dataService.getSeller(this.pending_request_list[i].seller_id).subscribe(
          response => {

            if (!response[0]) {
              console.error(response[1]);
              Swal.close();
              this.dataService.showMessage('Error inesperado', 'Error inesperado en la base de datos.', 'error');
              return;
            }

            this.sellers = response[1];
            if (this.pending_request_list[i].seller_id == this.sellers[0].seller_id) {
              this.pending_request_list[i].seller_name = this.sellers[0].seller_name;
              this.pending_request_list[i].seller_lastname = this.sellers[0].seller_lastname;
            }
        });
      }
    });
  }


  generateOrder() {
    this.disableButtons = true;
    /*fecha*/
    let fecha : Date = new Date();
    let order_date:string;
    let y = `${fecha.getFullYear()}`;
    let m = `${fecha.getMonth() + 1}`;
    let d = `${fecha.getDay()}`;
    order_date = `${y}-${(m.length < 2) ? '0' + m : m}-${(d.length < 2) ? '0' + d : d}`;

    /* falta validar que estatus se coloque en 1*/
      /*insertar compra*/
    this.dataService.insertOrder(order_date,JSON.stringify(this.carRequest_list.map(item => { request_id: item.request_id
      return item;})),this.grade_id).subscribe(response =>{
      console.log(response);
      this.disableButtons = false;
      if( response[0] == true){
       this.dataService.showMessage('Compra generada', '', 'success');
       this.carRequest_list = [];
       let localId = this.dataService.getUserId();
        this.dataService.insertLog(null, localId, null, "Compra generada").subscribe(responseLog=>{
          console.log(responseLog);
        });
      } else {
        this.dataService.showMessage('Error en la compra', '', 'error');
        let localId = this.dataService.getUserId();
        this.dataService.insertLog(null, localId, null, "Error en la compra").subscribe(responseLog=>{
          console.log(responseLog);
        });
      }
    });
  }
}
