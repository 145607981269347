<mat-card class="col-lg-6 col-md-12 col-sm-12 mx-auto mt-5 fade-in">
  <mat-card-header >
    <mat-card-title >Administrar fecha de tramitación de pedidos</mat-card-title>
  </mat-card-header>
  <mat-card-content class="p-3">
    <form>
      <mat-form-field class="d-block w-75 p-3 mx-auto">
        <mat-datepicker-toggle matSuffix [for]="picker" mat-icon-align="end">
          <mat-icon>calendar_today</mat-icon>
        </mat-datepicker-toggle>
        <input matInput [matDatepicker]="picker" [(ngModel)]="date"
        [ngModelOptions]="{standalone: true}">
        <mat-hint>MM/DD/YYYY</mat-hint>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
      
      <button mat-flat-button color="primary" (click)="updateDate()">Guardar</button>
    </form>
  </mat-card-content>
</mat-card>
