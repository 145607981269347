<mat-card class="fade-in proviaje-last-item">
    <mat-card-header>
      <mat-card-title>Lista de eventos</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="table-container">
        <app-table-log [logList]="logList"></app-table-log>
      </div>
    </mat-card-content>
</mat-card>
