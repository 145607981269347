import { Component, OnInit, Input, ViewChild, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DataMasterService } from 'src/app/services/data-master.service';
import { Request } from 'src/app/classes/request.class';
import { Seller } from 'src/app/classes/seller.class';

@Component({
  selector: 'app-table-course',
  templateUrl: './table-course.component.html',
  styleUrls: ['./table-course.component.scss']
})
export class TableCourseComponent implements OnInit {
  readonly DEFAULT_CONFIG : string = 'default';
  readonly CART_CONFIG : string = 'car';
  previuosOrder: boolean = false;

  @Input() disableButtons : boolean = false;
  @Input() tableConfig : string = 'user';
  @Input() userType : string;
  @Input() order:string;
  @Input() orderM:string;
  @Input() request_List : Request[] = [];

  @Output() addRequest : EventEmitter<Request> = new EventEmitter<Request>();
  @Output() deleteFromCar : EventEmitter<Request> = new EventEmitter<Request>();
  @Output() generateOrder : EventEmitter<Request> = new EventEmitter<Request>();

  private showDeleteFromCarButton : boolean = false;
  private showAddButton : boolean = false;

  titulo:string;

  displayedColumns = ['seller_name','request_date', 'request_total','request_totalprofit' , 'action'];

  requestDataSource: MatTableDataSource<Request>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  paddingColumnsResponsive: boolean;

  constructor(public dataService : DataMasterService ) {
    this.requestDataSource= new MatTableDataSource(this.request_List);
  }


  ngOnChanges(changes: SimpleChanges) : void {
    if (changes['request_List'] != null) {

      this.requestDataSource.data = this.request_List;
      this.requestDataSource.paginator = this.paginator;
      this.requestDataSource.sort = this.sort;
    }

    if (changes['tableConfig'] != null) {
      this.loadConfig();
    }
  }
  private loadConfig() {
    switch (this.tableConfig) {
      case this.DEFAULT_CONFIG: {
        this.loadDefaultRequestList();
        break;
      }
      case this.CART_CONFIG: {
        this.loadCarRequestList();
        break;
      }
    }
  }

  private loadDefaultRequestList() {
    /*agregar nombre de curso*/
    this.titulo="Pedidos solicitados por alumnos";
    this.showAddButton = true;
  }

  private loadCarRequestList() {
    this.titulo="Pedidos a tramitar"
    this.showDeleteFromCarButton = true;

  }

  ngOnInit() : void {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.paddingColumnsResponsive = true; 
    }else{
      this.paddingColumnsResponsive = false; 
    }
  }

  addRequestTrigger(request: Request) {

    this.addRequest.emit(request);
  }

  deleteFromCarTrigger(request: Request) {

    this.deleteFromCar.emit(request);
  }

  generateOrderTrigger() {
    this.dataService.showMessage({
      title: `Porfavor, Si no has agregado todos los pedidos de los alumnos que quieres tramitar, Vuelve atrás y agregalos todos para continuar.`,
      text: "Si has agregado todos los pedidos que desea, pulse 'tramitar la compra' ",
      icon: 'warning',  
      iconColor: 'red',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Tramitar la Compra',
      cancelButtonText: 'Volver atrás'
    }).then((result) => {
          if (result.value) {
    this.generateOrder.emit();
  }})
  };
  
  getTotalSalePrice() : number {
    const totalCostArray = this.request_List
      .map(item => typeof item.request_totalprofit == 'string' ?
        parseFloat(item.request_totalprofit) : item.request_totalprofit);
    return totalCostArray.reduce((previous, total) => previous + total);
  }

  getTotalProfit() : number {
    const totalSaleCost = this.request_List
      .map(item => typeof item.request_totalprofit == 'string' ?
        parseFloat(item.request_totalprofit) : item.request_totalprofit)
        .reduce((previous, total) => previous + total);

    const totalSellerCost = this.request_List
      .map(item => typeof item.request_total == 'string' ?
        parseFloat(item.request_total) : item.request_total)
        .reduce((previous, total) => previous + total);

    return totalSellerCost - totalSaleCost;
  }
}
