import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators, ValidationErrors, AbstractControl, CheckboxRequiredValidator } from '@angular/forms';
import { Observable } from 'rxjs';

import { DataMasterService } from 'src/app/services/data-master.service';
import { Grade } from './../../classes/grade.class';
import { Moderator } from './../../classes/moderator.class';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  seller_password : string;
  r_password : string;
  seller_name : string;
  seller_lastname : string;
  seller_email : string;
  seller_phonenumber : string;
  grade_id : string;
  moderator_id : string;
  moderator_text = "Escriba un codigo de coordinador";

  firstFormGroup : FormGroup;
  secondFormGroup : FormGroup;

  courseData : Grade[] = [];
  moderatorData : Moderator;

  constructor(private dataService : DataMasterService, private _formBuilder : FormBuilder,
    private router : Router, private location : Location) {
  }

  // variable
  show_button: Boolean = false;
  show_eye: Boolean = false;

//Function
showPassword() {
    this.show_button = !this.show_button;
    this.show_eye = !this.show_eye;
  }

    // variable
    show_button1: Boolean = false;
    show_eye1: Boolean = false;
  
  //Function
  showPassword1() {
      this.show_button1 = !this.show_button1;
      this.show_eye1 = !this.show_eye1;
    }
  

  registro() {
    //validate phone
    if (this.seller_phonenumber != null) {
      var str = this.seller_phonenumber.replace(/\s/g, '');
      var phoneValidate = str.length === 9 && /^[679]{1}[0-9]{8}$/.test(str);
      if ( phoneValidate == false) {
        this.dataService.showMessage('Campos invalidos', 'El número de telefono no cumple con los parametros adecuados.', 'error');
        return;
      }
    }
    
    //validate email
    if(this.seller_email != null){
      var emailValidate =  /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.seller_email);
      if ( emailValidate  == false) {
        this.dataService.showMessage('Campos invalidos', 'El email no cumple con los parametros adecuados.', 'error');
        return;
      }
    }

    //validate password
    if (this.seller_password != null) {
      var passwordValidate =  /(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/.test(this.seller_password);
      if ( passwordValidate  == false) {
        this.dataService.showMessage('Campos invalidos', 'La contraseña ingresada debe contener al menos 1 minúscula, 1 mayúscula, 1 número, y un tamaño mínimo de 8 carcateres.', 'error');
        return;
      }
    }
    
      //validate selec course
      if (this.grade_id == null) {
        this.dataService.showMessage('Campos invalidos', 'Por favor, seleccione un curso.', 'error');
        return;
      }

    //validate check
    if (this.secondFormGroup.value.tos == false) {
      this.dataService.showMessage('Campos invalidos', 'Debe aceptar los terminos y condiciones', 'error');
      return;
    } 

    if (this.secondFormGroup.invalid) {
      this.secondFormGroup.get('tos').markAsTouched();
      this.dataService.showMessage('Campos invalidos', 'Por favor, rellene de forma valida los campos marcados antes de continuar.', 'error');
      return;
    }

    if (this.seller_password.length <= 0 || this.seller_password != this.r_password) {
      this.dataService.showMessage('Las contraseñas no coinciden', '', 'error');
    } else {
      this.dataService.regUser(this.seller_name ,this.seller_password ,this.seller_lastname,
        this.seller_email,this.grade_id,this.seller_phonenumber).subscribe(respuesta => {
        if (respuesta[0] == false) {
          //console.log(respuesta);
          if (respuesta[1] == 'el correo electronico que desea colocar ya esta en uso') {
            this.dataService.showMessage('El correo que ha introducido ya esta en uso', '', 'error');
            this.dataService.insertLog('0', null, null, "Error al registrar usuario, correo en uso",this.seller_name,this.seller_lastname,this.moderatorData.moderator_institution).subscribe(responseLog=>{
              console.log(responseLog);
            });
            return;
          }
          this.dataService.showMessage('El usuario no pudo ser creado', '', 'error');
          this.dataService.insertLog('0', null, null, "El usuario no pudo ser creado",this.seller_name,this.seller_lastname,this.moderatorData.moderator_institution).subscribe(responseLog=>{
            console.log(responseLog);
          });
        } else {
          this.dataService.showMessage('Usuario creado', '', 'success');
          this.dataService.insertLog('0', null, null, "Usuario creado: "+ this.seller_email,this.seller_name,this.seller_lastname,this.moderatorData.moderator_institution).subscribe(responseLog=>{
            console.log(responseLog);
          });
          //console.log(respuesta);
          this.router.navigate(['/options']);
        }
      });
    }
  }

  ngOnInit(): void {
    this.firstFormGroup = this._formBuilder.group({
      codeCtrl: ['', [Validators.required],
      this.validateCode.bind(this)
    ]
    });

    this.secondFormGroup = this._formBuilder.group({
      nameCtrl: ['', [Validators.required]],
      lastnameCtrl: ['', [Validators.required]],
      emailCtrl: ['', [Validators.required, Validators.email]],
      phoneCtrl: ['', [Validators.pattern('^[0-9]*$'), Validators.minLength(9)]],
      passCtrl: ['', [Validators.required, Validators.minLength(8)]],
      repPassCtrl: ['', [Validators.required, Validators.minLength(8)]],
      courseCtrl: ['', [Validators.required]],
      tos: [false, [Validators.requiredTrue]]
    });
  }

  validateCode(control: AbstractControl): Observable<ValidationErrors|null> {
    return new Observable<ValidationErrors | null>((subscriber) => {
      if (isNaN(control.value)) {
        const error = {
          codeNumberError: control.value
        };
        subscriber.next(error);
        subscriber.complete();
        return;
      }

      const controlValue = +control.value;
      this.dataService.getModerator(controlValue).subscribe(response => {
        //console.log(response);

        if (response[0] && Array.isArray(response[1]) && response[1].length > 0) {
          this.moderatorData = response[1][0];
          this.dataService.getCourseByModerator(controlValue).subscribe(response => {
            //console.log(response);

            if (response[0] && Array.isArray(response[1]) && response[1].length > 0) {
              //console.log("Courses found");
              this.courseData = response[1];
              subscriber.next(null);
            } else {
              //console.log("There's no course registered");

              this.dataService.showMessage('El coordinador no ha creado ningun curso',
                        'No se podra continuar con el registro, recuerda a tu coordinador que debe crear los cursos para que los alumnos puedan registrarse.',
                        'warning');

              const error = {
                codeExistError: control.value
              }
              subscriber.next(error);
            }

            subscriber.complete();
          });
        } else {
          //console.log("Moderator doesn't exist");
          this.moderator_text = "Ingrese un codigo de coordinador correcto";
          this.moderatorData = null;
          const error = {
            codeExistError: control.value
          }
          subscriber.next(error);
        }
      });
    });
  }

  backLocation() : void {
    this.location.back();
  }
}
