import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup } from '@angular/forms';

import { DataMasterService, USER_TYPE_MOD } from 'src/app/services/data-master.service';
import { Seller } from 'src/app/classes/seller.class';
import { Grade } from 'src/app/classes/grade.class';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss']
})
export class UserDetailComponent implements OnInit {
  seller_password : string;
  r_password : string;
  seller_id : number;
  seller_name : string;
  seller_lastname : string;
  seller_email : string;
  seller_phonenumber : string;
  grade_id : number;

  courseData : Grade[] = [];

  moderator_id : number;
  userType : string;

  @ViewChild('userForm') userForm : FormGroup;

  constructor(private dataService : DataMasterService,
    private activatedRoute : ActivatedRoute, private router : Router, private location: Location) {

    this.activatedRoute.params.subscribe(params => {
      this.seller_id = params.id;

      if (params.type == USER_TYPE_MOD) {
        this.moderator_id = parseInt(this.dataService.getUserId(), 10);
      }
      this.userType = params.type;

      this.dataService.showMessage("Cargando");
      Swal.showLoading();
      this.loadSellerData();
    });
  }

 // variable
 show_button: Boolean = false;
 show_eye: Boolean = false;

//Function
showPassword() {
   this.show_button = !this.show_button;
   this.show_eye = !this.show_eye;
 }

    // variable
    show_button1: Boolean = false;
    show_eye1: Boolean = false;
  
  //Function
  showPassword1() {
      this.show_button1 = !this.show_button1;
      this.show_eye1 = !this.show_eye1;
    }

  loadSellerData() {
    this.dataService.getSeller(this.seller_id).subscribe(response => {
      if (!response[0]) {
        console.error(response[1]);
        Swal.close();
        this.dataService.showMessage('Error inesperado', 'Error inesperado en la base de datos.', 'error');
        return;
      }

      const seller = response[1][0];
      this.seller_name = seller.seller_name;
      this.seller_lastname = seller.seller_lastname;
      this.seller_email = seller.seller_email;
      this.seller_phonenumber = seller.seller_phonenumber;
      this.grade_id = seller.grade_id;
      if(this.seller_phonenumber == "undefined"){
        this.seller_phonenumber = "No facilito";
      }

      if (this.moderator_id == null) {
        this.loadModeratorData();
      } else {
        this.loadGradeList();
      }
    });
  }

  loadModeratorData() {
    this.dataService.getGradeId(this.grade_id.toString()).subscribe(response => {
      console.log(response);
      if (!response[0]) {
        console.error(response[1]);
        Swal.close();
        this.dataService.showMessage('Error inesperado', 'Error inesperado en la base de datos.', 'error');
        return;
      }

      this.moderator_id = response[1][0].moderator_id;
      this.dataService.getModerator(parseInt(response[1][0].moderator_id)).subscribe(response => {
        console.log(response);
        if (!response[0]) {
          console.error(response[1]);
          Swal.close();
          this.dataService.showMessage('Error inesperado', 'Error inesperado en la base de datos.', 'error');
          return;
        }
  
        this.moderator_id = response[1][0].moderator_institution;
      this.loadGradeList();
    });
          //this.loadGradeList();
        });
  }

  loadGradeList() {
    if (this.userType == "admin") {
      this.dataService.getAllGrade().subscribe(response => {
        if (!response[0]) {
          console.error(response[1]);
          Swal.close();
          this.dataService.showMessage('Error inesperado', 'Error inesperado en la base de datos.', 'error');
          return;
        }
  
        this.courseData = response[1];
        Swal.close();
      });
    }else{
    this.dataService.getGrade(this.moderator_id).subscribe(response => {
      if (!response[0]) {
        console.error(response[1]);
        Swal.close();
        this.dataService.showMessage('Error inesperado', 'Error inesperado en la base de datos.', 'error');
        return;
      }

      this.courseData = response[1];
      Swal.close();
    });
  }

}

  ngOnInit(): void {
  }

  updateSeller() {
    if (this.userForm.invalid) {
      this.dataService.showMessage('Campos invalidos', 'Por favor, rellene de forma valida los campos marcados.', 'error');
      return;
    }

    if (this.seller_password != null && this.seller_password != this.r_password) {
      this.dataService.showMessage('Las contraseñas no coinciden', '', 'error');
    } else {
      this.dataService.updateSeller(this.seller_id, this.seller_name,
        this.seller_lastname, this.seller_email, this.seller_phonenumber,
        this.grade_id, this.seller_password).subscribe(response =>{
        console.log(response);
        if (response[0] == true) {
          this.dataService.showMessage('Usuario modificado', '', 'success');
          const userType = this.dataService.getUserType();
         /*this.router.navigate(userType == USER_TYPE_MOD ? ['/mod', 'admin-users'] :
            ['/admin', 'admin-users', this.moderator_id]);*/
            this.location.back()
        } else {
          this.dataService.showMessage('No se pudo modificar el usuario', '', 'error');
        }
      });
    }
  }

}
