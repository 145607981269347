
<div class="d-flex justify-content-center align-items-center" style="height:50vh;">
    <div class="col-md-6">
        <h1 class="text-center mb-4">{{text}}</h1>
        <div class="custom-control custom-switch custom-switch-xl text-center">
            <input type="checkbox" class="custom-control-input" id="customSwitch1" [(ngModel)]="active" (change)="onToggle($event)">
            <label class="custom-control-label" for="customSwitch1"></label>
        </div>
    </div>
</div>
