<div class="text-center">
    <h2 *ngIf=" excelList.length < 0">No se han hecho volcados actualmente</h2>
    <table mat-table *ngIf="excelList.length > 0" [dataSource]="dataSource" matSort>
  
      <ng-container matColumnDef="id" sticky class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true">
        <th mat-header-cell *matHeaderCellDef class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> Id </th>
        <td mat-cell *matCellDef="let excel" class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> {{excel.id}} </td>
      </ng-container>
  
      <ng-container matColumnDef="institute">
        <th mat-header-cell *matHeaderCellDef class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> Centro </th>
        <td mat-cell *matCellDef="let excel" class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> {{excel.institute}} </td>
      </ng-container>

      <ng-container matColumnDef="moderator">
        <th mat-header-cell *matHeaderCellDef class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> Coordinador </th>
        <td mat-cell *matCellDef="let excel" class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> {{excel.moderator}} </td>
      </ng-container>
  
      <ng-container matColumnDef="course">
        <th mat-header-cell *matHeaderCellDef class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> Curso </th>
        <td mat-cell *matCellDef="let excel" class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> {{ excel.course }} </td>
      </ng-container>

      <ng-container matColumnDef="total_to_pay">
        <th mat-header-cell *matHeaderCellDef class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> Total a Pagar </th>
        <td mat-cell *matCellDef="let excel" class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> {{ excel.total_to_pay | currency : "EUR" : "symbol" }} </td>
      </ng-container>

      <ng-container matColumnDef="income">
        <th mat-header-cell *matHeaderCellDef class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> Ingresos </th>
        <td mat-cell *matCellDef="let excel" class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> {{ excel.income | currency : "EUR" : "symbol"}} </td>
      </ng-container>

      <ng-container matColumnDef="benefit">
        <th mat-header-cell *matHeaderCellDef class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> Beneficio </th>
        <td mat-cell *matCellDef="let excel" class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> {{ excel.benefit | currency : "EUR" : "symbol" }} </td>
      </ng-container>

      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> Fecha </th>
        <td mat-cell *matCellDef="let excel" class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> {{ excel.date | date}} </td>
      </ng-container>
  
      <ng-container matColumnDef="actions" >
        <th mat-header-cell *matHeaderCellDef class="text-center" [class.pr-2]="paddingColumnsResponsive == true" [class.pl-2]="paddingColumnsResponsive == true"> Opciones </th>
        <td mat-cell *matCellDef="let excel" class="text-center" [class.pr-1]="paddingColumnsResponsive == true" [class.pl-1]="paddingColumnsResponsive == true">
          <div class="mx-auto" [class.custom-column-px]="paddingColumnsResponsive == true">
            <button matTooltip="Generar PDF" (click)="generatePdf(excel)" mat-icon-button><mat-icon>picture_as_pdf</mat-icon></button>
          </div>
        </td>
      </ng-container>
  
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;">
      </tr>
  
    </table>
  
</div>
  
