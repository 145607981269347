import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup } from '@angular/forms';

import { DataMasterService, USER_TYPE_MOD } from './../../../services/data-master.service';

import { Grade } from './../../../classes/grade.class';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-register-user',
  templateUrl: './register-user.component.html',
  styleUrls: ['./register-user.component.scss']
})
export class RegisterUserComponent implements OnInit {
  grade_id : string;
  seller_name : string;
  seller_password : string;
  repeat_password : string;
  seller_lastname : string;
  seller_email : string;
  seller_phonenumber : string;

  courseData : Grade[] = [];

  moderator_id : number;

  userType : string;

  @ViewChild('registerForm') registerForm : FormGroup;

  constructor(private dataService : DataMasterService,
    private activatedRoute : ActivatedRoute, private router : Router) {

    this.activatedRoute.params.subscribe(params =>{
      this.grade_id = params.grade_id;
      this.loadGradeData();
    });
  }

 // variable
 show_button: Boolean = false;
 show_eye: Boolean = false;

//Function
showPassword() {
   this.show_button = !this.show_button;
   this.show_eye = !this.show_eye;
 }

    // variable
    show_button1: Boolean = false;
    show_eye1: Boolean = false;
  
  //Function
  showPassword1() {
      this.show_button1 = !this.show_button1;
      this.show_eye1 = !this.show_eye1;
    }

  loadGradeData() {
    this.dataService.getGradeId(this.grade_id).subscribe(response =>{
      if (!response[0]) {
        console.error(response[1]);
        Swal.close();
        this.dataService.showMessage('Error inesperado', 'Error inesperado en la base de datos.', 'error');
        return;
      }

      const grade = response[1][0];
      this.moderator_id = grade.moderator_id;
      this.loadGradeList();
    });
  }

  loadGradeList() {
    this.dataService.getGrade(this.moderator_id).subscribe(response => {
      if (!response[0]) {
        console.error(response[1]);
        Swal.close();
        this.dataService.showMessage('Error inesperado', 'Error inesperado en la base de datos.', 'error');
        return;
      }

      if (Array.isArray(response[1])) {
        this.courseData = response[1];
      } else {
        this.dataService.showMessage("No hay resultados", "No hay cursos cargados actualmente", "error");
      }
    });
  }

  regSeller() {
    
     //validate email
     if(this.seller_email != null){
      var emailValidate =  /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.seller_email);
      if ( emailValidate  == false) {
        this.dataService.showMessage('Campos invalidos', 'El email no cumple con los parametros adecuados.', 'error');
        return;
      }
    }

    //validate password
    if (this.seller_password != null) {
      var passwordValidate =  /(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/.test(this.seller_password);
      if ( passwordValidate  == false) {
        this.dataService.showMessage('Campos invalidos', 'La contraseña ingresada debe contener al menos 1 minúscula, 1 mayúscula, 1 número, y un tamaño mínimo de 8 carcateres.', 'error');
        return;
      }
    }
    console.log(this.registerForm);


    if (this.registerForm.invalid) {
      this.dataService.showMessage('Campos invalidos', 'Por favor, rellene de forma valida los campos marcados.', 'error');
      return;
    }

    if (this.seller_password.length <= 0 || this.seller_password != this.repeat_password) {
      this.dataService.showMessage('Las contraseñas no coinciden', '', 'error');
    } else {
      this.dataService.regUser(this.seller_name, this.seller_password,
      this.seller_lastname, this.seller_email, this.grade_id, this.seller_phonenumber).
      subscribe(response => {

        if (!response[0]) {
          console.error(response[1]);
          Swal.close();
          if (response[1] == 'el correo electronico que desea colocar ya esta en uso') {
            this.dataService.showMessage('El correo que ha introducido ya esta en uso', '', 'error');
            return;
          }
          this.dataService.showMessage('Error inesperado', 'Error inesperado en la base de datos.', 'error');
          return;
        }

        this.dataService.showMessage('Alumno registrado', '', 'success');
        let localId = this.dataService.getUserId();
        if (this.userType == "user") {
          this.dataService.insertLog(localId, null, null, "Usuario registrado").subscribe(responseLog=>{
            console.log(responseLog);
          });
        }else if(this.userType == "mod"){
          this.dataService.insertLog(null, localId, null, "Usuario registrado").subscribe(responseLog=>{
            console.log(responseLog);
          });
        }else{
          this.dataService.insertLog(null, null, localId, "Usuario registrado").subscribe(responseLog=>{
            console.log(responseLog);
          });
        }
        const userType = this.dataService.getUserType();
        this.router.navigate(userType == USER_TYPE_MOD ? ['/mod', 'admin-users'] :
          ['/admin', 'admin-users', this.moderator_id]);
      });

    }
  }

  ngOnInit(): void {
  }

}
