<div class="text-center">
  <h2 *ngIf="orderList.length < 1">No hay compras cargadas</h2>
  <table mat-table *ngIf="orderList.length > 0" [dataSource]="orderDataSource" matSort>

    <ng-container matColumnDef="orders_id" sticky class="pr-3 pl-3" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true" *ngIf="showGrade == true">
      <th mat-header-cell *matHeaderCellDef class="text-center pr-3 pl-3 mr-3 ml-3 " [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> Id </th>
      <td mat-cell *matCellDef="let order" class="text-center pr-3 pl-3 mr-3 ml-3 " [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> {{order.orders_id}} </td>
    </ng-container>

    <ng-container matColumnDef="orders_id" *ngIf="showGrade == false" class="pr-3 pl-3">
      <th mat-header-cell *matHeaderCellDef class="text-center pr-3 pl-3 mr-3 ml-3 " [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> Id </th>
      <td mat-cell *matCellDef="let order" class="text-center pr-3 pl-3 mr-3 ml-3 " [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> {{order.orders_id}} </td>
    </ng-container>

    <ng-container matColumnDef="orders_institution" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true" *ngIf="showGrade == true">
      <th mat-header-cell *matHeaderCellDef class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> Centro </th>
      <td mat-cell *matCellDef="let order" class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> {{order.orders_institution}} </td>
    </ng-container>

    <ng-container matColumnDef="orders_institution" *ngIf="showGrade == false">
      <th mat-header-cell *matHeaderCellDef class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> Centro </th>
      <td mat-cell *matCellDef="let order" class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> {{order.orders_institution}} </td>
    </ng-container>

    <ng-container matColumnDef="orders_grade">
      <th mat-header-cell *matHeaderCellDef class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> Curso </th>
      <td mat-cell *matCellDef="let order" class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> {{order.orders_grade}} </td>
    </ng-container>

    <ng-container matColumnDef="orders_date" sticky *ngIf="showGrade == false && showInstitute == false">
      <th mat-header-cell *matHeaderCellDef class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> Fecha de compra </th>
      <td mat-cell *matCellDef="let order" class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> {{order.orders_date | date}} </td>
    </ng-container>

    <ng-container matColumnDef="orders_date" *ngIf="showGrade == true || showInstitute == true">
      <th mat-header-cell *matHeaderCellDef class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> Fecha de compra </th>
      <td mat-cell *matCellDef="let order" class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> {{order.orders_date | date}} </td>
    </ng-container>

    <ng-container matColumnDef="orders_totalprofit" style="text-align:center;">
      <th mat-header-cell *matHeaderCellDef class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> Precio total de compra </th>
      <td mat-cell *matCellDef="let order" class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> {{order.orders_totalprofit | currency : "EUR" : "symbol"}} </td>
    </ng-container>

    <ng-container matColumnDef="orders_total" style="text-align:center;">
      <th mat-header-cell *matHeaderCellDef class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> Precio total de venta </th>
      <td mat-cell *matCellDef="let order" class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> {{order.orders_total | currency : "EUR" : "symbol"}} </td>
    </ng-container>

    <ng-container matColumnDef="orders_total_profit" style="text-align:center;">
      <th mat-header-cell *matHeaderCellDef class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> Total beneficio </th>
      <td mat-cell *matCellDef="let order" class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> {{order.orders_total - order.orders_totalprofit | currency : "EUR" : "symbol"}} </td>
    </ng-container>

    <ng-container matColumnDef="action" class="text-center" [class.pr-1]="paddingColumnsResponsive == true" [class.pl-1]="paddingColumnsResponsive == true">
      <th mat-header-cell *matHeaderCellDef class="text-center" class="text-center" [class.pr-1]="paddingColumnsResponsive == true" [class.pl-1]="paddingColumnsResponsive == true"> Opciones </th>
      <td mat-cell *matCellDef="let order"  [class.pr-1]="paddingColumnsResponsive == true" [class.pl-1]="paddingColumnsResponsive == true">
        <div class="mx-auto" [class.custom-column-px]="paddingColumnsResponsive == true">
          <button (click)="toggleRead(order)"
                [matTooltip]="order.orders_readed == 1 ? 'Marcar como no leido' : 'Marcar como leido'"
                *ngIf="showMark"
                class="mr-2"
                mat-icon-button>
                <mat-icon [class.text-success]="order.orders_readed == 1">
                  {{ order.orders_readed == 1 ? 'mark_email_read' : 'mark_email_unread' }}
                </mat-icon>
          </button>
          <button mat-raised-button color="primary" [routerLink]="['/' + userType, 'sales-detail', order.orders_id]" class="mr-2">Detalles</button>
          <button matTooltip="Generar PDF" (click)="generatePdfDinamic(order)" *ngIf="showGeneratePDF" class="mr-2" mat-icon-button><mat-icon>picture_as_pdf</mat-icon></button>
         
          <mat-form-field class="w-25" *ngIf="showGeneratePDF">
            <mat-label>PDF</mat-label>
            <mat-select>
              <mat-option *ngFor="let category of order.categories">
                <div class="form-check d-flex align-items-center">
                  <input class="form-check-input" type="checkbox" (click)="addCheck(category,order)">
                  <span class="form-check-label" for="defaultCheck1">
                    {{category.category_name}}
                  </span>
                </div>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
      [ngClass]="{'readed': showMark && row.orders_readed == 1, 'unread' : showMark && row.orders_readed == 0 }">
  </tr>

  </table>

  <!-- <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator> -->
</div>
