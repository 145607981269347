import { Component, OnInit } from '@angular/core';
import { DataMasterService } from 'src/app/services/data-master.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-admin-web-status',
  templateUrl: './admin-web-status.component.html',
  styleUrls: ['./admin-web-status.component.scss']
})
export class AdminWebStatusComponent implements OnInit {
  active: boolean = false;
  text = "Web inactiva";

  constructor(private dataService : DataMasterService) { }

  ngOnInit(): void {
    this.loadWebStatus();
  }

  loadWebStatus(){
    this.dataService.getWebStatus().subscribe(responseWeb => {
      console.log(responseWeb);
      if (!responseWeb[0]) {
        console.error(responseWeb[1]);
        Swal.close();
        this.dataService.showMessage('Error inesperado', 'Error inesperado en la base de datos.', 'error');
        return;
      }

      if (responseWeb[1] == "no result") {

        this.dataService.showMessage('Debe tener un registro en la tabla webstatus.', '', 'warning');
        
      } else {
        if (responseWeb[1][0].status == '1') {
          this.active = false;
          this.text = "Web inactiva";
        }else{
          this.active = true;
          this.text = "Web activa";
        }

      }
    });
  }
  onToggle(event: any) {
    console.log(this.active);
    if (this.active == true) {
      this.text = "Web activa";
      this.dataService.updateWebStatus(0).subscribe(responseUpdate =>{
        console.log(responseUpdate);
        this.dataService.showMessage('La web fue habilidata correctamente', '', 'info');
        this.dataService.insertLog(null, null, this.dataService.getUserId(), "Web habilitada correctamente").subscribe(responseLog=>{
          console.log(responseLog);
        });
      });
    }else{
      this.text = "Web inactiva";
      this.dataService.updateWebStatus(1).subscribe(responseUpdate =>{
        console.log(responseUpdate);
        this.dataService.showMessage('La web se ha puesto en manteminento', '', 'info');
        this.dataService.insertLog(null, null, this.dataService.getUserId(), "Web en mantenimiento").subscribe(responseLog=>{
          console.log(responseLog);
        });
      });
    }
  }

}
