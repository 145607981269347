import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataMasterService } from 'src/app/services/data-master.service';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit {

  constructor(public dataService : DataMasterService, private router : Router ) {
    this.dataService.setSideNav(false);
  }

  ngOnInit(): void {
    this.dataService.getWebStatus().subscribe(responseWeb =>{
      if (responseWeb[1][0].status != "1") {
        this.webNotFound();
      }
    });
  }
  webNotFound() {
    this.dataService.showMessage('Volvemos a estar operativo', '', 'success');
    this.router.navigate(['/options']);
  }

}