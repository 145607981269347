import { Component, OnInit } from '@angular/core';
import { DataMasterService } from 'src/app/services/data-master.service';
import { Log } from 'src/app/classes/log.class';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-admin-log',
  templateUrl: './admin-log.component.html',
  styleUrls: ['./admin-log.component.scss']
})
export class AdminLogComponent implements OnInit {

  logList : Log[] = [];

  constructor(public dataService : DataMasterService) { 
    this.dataService.setFooter("fixed");
    this.loadLog();
  }

  ngOnInit(): void {
    
  }

  loadLog() {
    this.dataService.getAllLogs().subscribe(response => {
      console.log("Logs");
      console.log(response);
      if (!response[0]) {
        console.error(response[1]);
        Swal.close();
        this.dataService.showMessage('Error inesperado', 'Error inesperado en la base de datos.', 'error');
        return;
      }
      if (response[1] == "no result") {
        this.logList = [];
      } else {
        this.logList = response[1].filter(item => item.email != null);
      }
    });
  }

}
