<div class="text-center">
    
  <h2 *ngIf="logList.length < 1">No hay registros en el log</h2>
  <mat-form-field class="w-25 m-2" *ngIf="logList.length > 0">
    <mat-label>Buscar evento
    </mat-label>
    <input matInput (keyup)="filter($event)">
  </mat-form-field>
  <table mat-table *ngIf="logList.length > 0" [dataSource]="logDataSource" matSort>

    <ng-container matColumnDef="id" sticky class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true">
      <th mat-header-cell *matHeaderCellDef class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> Id </th>
      <td mat-cell *matCellDef="let log" class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> {{log.id}} </td>
    </ng-container>

    <ng-container matColumnDef="name" style="text-align:center;">
      <th mat-header-cell *matHeaderCellDef class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> Nombre </th>
      <td mat-cell *matCellDef="let log" class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> {{ log.name }} </td>
    </ng-container>

    <ng-container matColumnDef="lastname" style="text-align:center;">
      <th mat-header-cell *matHeaderCellDef class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> Apellido </th>
      <td mat-cell *matCellDef="let log" class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> {{ log.lastname }} </td>
    </ng-container>

    <ng-container matColumnDef="institution" style="text-align:center;">
      <th mat-header-cell *matHeaderCellDef class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> Centro </th>
      <td mat-cell *matCellDef="let log" class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> {{ log.institution }} </td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> Usuario </th>
      <td mat-cell *matCellDef="let log" class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> {{log.email}} </td>
    </ng-container>

    <ng-container matColumnDef="description" style="text-align:center;">
      <th mat-header-cell *matHeaderCellDef class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> Descripción </th>
      <td mat-cell *matCellDef="let log" class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> {{ log.description }} </td>
    </ng-container>

    <ng-container matColumnDef="date" style="text-align:center;">
      <th mat-header-cell *matHeaderCellDef class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> Fecha </th>
      <td mat-cell *matCellDef="let log" class="text-center" [class.pr-3]="paddingColumnsResponsive == true" [class.pl-3]="paddingColumnsResponsive == true"> {{ log.date }} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
    </tr>

  </table>
  
  </div>
  
