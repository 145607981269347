<!-- <button [routerLink]="['/catalogue']" class="back-button m-5" mat-icon-button><mat-icon>arrow_back</mat-icon></button> -->
<section class="pt-3 pb-3 bg-red inner-header fondo">
	<div class="text-center">
		<img src="assets/logo.png" class="col-12 col-md-6">
	</div>
</section>
<div class="text-center bg-red p-2">
	<button mat-button [routerLink]="['/catalogue']"><mat-icon class="mx-2">home</mat-icon>Inicio</button>
	<button mat-button [routerLink]="['/sales']"><mat-icon class="mx-2">attach_money</mat-icon>Promociones</button>
	<button mat-button [routerLink]="['/contact']"><mat-icon class="mx-2">local_phone</mat-icon>Contacto</button>
	<button mat-button [routerLink]="['/options']"><mat-icon class="mx-2">person</mat-icon>Iniciar sesion</button>
</div>

<h1 class="text-center m-5"></h1>
<h1 style="text-align: center;">Por la compra de cada producto de Don Juan, obtendrás 4 papeletas totalmente gratuitas para un GRAN ORDENADOR PORTATIL.</h1>
<h1 style="text-align: center;">A mas compra de productos Don Juan, más papeletas obtendras.</h1>
<p style="text-align: center;"><img src="assets/papeleta.jpg" width="400vw"/></p>
<p>&nbsp;</p>
<h1 style="text-align: center;">Ademas, si tu centro realiza una compra superior a 1.000€ obtendra 1.000 papeletas para el sorteo de un Iphone y un Patinete electrico.</h1>
<p style="text-align: center;"><img src="assets/movil.png" width="400vw"/><img src="assets/patin.png" width="400vw"/></p>
<div class="p-5 partners">
	<div class="text-center">
		<img 	src="assets/partners/p6.png"
					alt="asdf"
					class="col-12 col-md-2 col-lg-1 mx-md-5">
		<img 	src="assets/partners/p1.png"
					alt="asdf"
					class="col-12 col-md-2 col-lg-1 mx-md-5">
		<img 	src="assets/partners/p2.png"
					alt="asdf"
					class="col-12 col-md-2 col-lg-1 mx-md-5">
		<img 	src="assets/partners/p3.png"
					alt="asdf"
					class="col-12 col-md-2 col-lg-1 mx-md-5">
		<img 	src="assets/partners/p4.png"
					alt="asdf"
					class="col-12 col-md-2 col-lg-1 mx-md-5">
		<img 	src="assets/partners/p5.png"
					alt="asdf"
					class="col-12 col-md-2 col-lg-1 mx-md-5">
	</div>
	<div class="text-center mb-5">
		<img 	src="assets/partners/p7.png"
					alt="asdf"
					class="col-12 col-md-2 col-lg-1 mx-md-5">
		<img 	src="assets/partners/p8.png"
					alt="asdf"
					class="col-12 col-md-2 col-lg-1 mx-md-5">
		<img 	src="assets/partners/p9.png"
					alt="asdf"
					class="col-12 col-md-2 col-lg-1 mx-md-5">
		<img 	src="assets/partners/p10.png"
					alt="asdf"
					class="col-12 col-md-2 col-lg-1 mx-md-5">
		<img 	src="assets/partners/p11.png"
					alt="asdf"
					class="col-12 col-md-2 col-lg-1 mx-md-5">
		<img 	src="assets/partners/p12.png"
					alt="asdf"
					class="col-12 col-md-2 col-lg-1 mx-md-5">
	</div>
</div>
<footer class="proviaje-footer catalogue py-3">
	<div class="px-3 text-center">
		<strong>Copyright © {{actualYear}} proviaje.es</strong>
		Todos los derechos reservados.
		- Movil: 629 71 00 52 / 616 44 89 69 - E-mail: info@proviaje.es

		<div class="text-center">
			<a 	href="assets/aviso_legal.pdf"
					target="_blank">Aviso legal</a> -
			<a 	href="assets/politica_privacidad.pdf"
					target="_blank">Terminos y condiciones</a> -
			<a 	href="assets/proteccion_datos.pdf"
					target="_blank">Proteccion de datos</a>
		</div>
	</div>
</footer>
