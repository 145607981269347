<section class="pt-3 pb-3 bg-red inner-header fondo">
	<div class="text-center">
		<img src="assets/logo.png" class="col-12 col-md-6">
	</div>
</section>
<!-- <div class="top-left-menu">
	<button mat-button [routerLink]="['/catalogue']"><mat-icon class="mx-2">home</mat-icon>Inicio</button>
	<button mat-button [routerLink]="['/sales']"><mat-icon class="mx-2">attach_money</mat-icon>Promociones</button>
</div>
<div class="top-right-menu">
	<button mat-button [routerLink]="['/contact']"><mat-icon class="mx-2">local_phone</mat-icon>Contacto</button>
	<button mat-button [routerLink]="['/options']"><mat-icon class="mx-2">person</mat-icon>Iniciar sesion</button>
</div> -->
<div class="text-center bg-red p-2">
	<button mat-button [routerLink]="['/catalogue']"><mat-icon class="mx-2">home</mat-icon>Inicio</button>
	<button mat-button [routerLink]="['/sales']"><mat-icon class="mx-2">attach_money</mat-icon>Promociones</button>
	<button mat-button [routerLink]="['/contact']"><mat-icon class="mx-2">local_phone</mat-icon>Contacto</button>
	<button mat-button [routerLink]="['/options']"><mat-icon class="mx-2">person</mat-icon>Iniciar sesion</button>
</div>
<div class="w-100 bg-red">
	<div id="catalogueCarousel" class="carousel slide" data-ride="carousel">
  <ol class="carousel-indicators">
    <li data-target="#catalogueCarousel"
				[attr.data-slide-to]="i"
				[class.active]="i == 1"
				*ngFor="let product of products; let i = index"></li>
  </ol>
  <div class="carousel-inner p-5">
    <div 	class="carousel-item text-center"
					[class.active]="i == 1"
					*ngFor="let product of products; let i = index">
      <img 	[src]="product.product_image"
						alt="Imagen prodcuto"
						class="rounded carousel-product-image">
			<h1 class="my-2">{{ product?.product_name }}</h1>
    </div>
  </div>
  <a class="carousel-control-prev" href="#catalogueCarousel" role="button" data-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a 	class="carousel-control-next"
			href="#catalogueCarousel"
			role="button"
			data-slide="next">
    <span class="carousel-control-next-icon"
					aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a>
</div>
</div>
<div class="p-3">
	<div 	class="px-3 my-3 col-12 col-md-6 col-lg-3 d-md-inline-block"
				*ngFor="let product of products">
		<mat-card class="bg-red">
			<button mat-icon-button
							class="float-right m-2 text-white"
							[routerLink]="['/catalogue-detail', product.product_id]"><mat-icon>add</mat-icon></button>
			<mat-card-header class="bg-red">
				<mat-card-title class="catalogue-card-text">
					{{ product.product_name }}
					<!-- <button [routerLink]="['/catalogue-detail', product.product_id]" class="float-right align-top" mat-button>Detalles</button> -->
				</mat-card-title>
			</mat-card-header>
			<mat-card-content class="text-center p-0">
				<img  [src]="product.product_image" class="w-100 product-catalogue-image">
				<!-- <button [routerLink]="['/catalogue-detail', product.product_id]" class="mt-5" mat-button>Detalles</button> -->
			</mat-card-content>
		</mat-card>
	</div>
</div>
<mat-divider [inset]="true" class="d-md-none mx-5"></mat-divider>
<div class="p-5 partners">
	<div class="text-center">
		<img 	src="assets/partners/p6.png"
					alt="asdf"
					class="col-12 col-md-2 col-lg-1 mx-md-5">
		<img 	src="assets/partners/p1.png"
					alt="asdf"
					class="col-12 col-md-2 col-lg-1 mx-md-5">
		<img 	src="assets/partners/p2.png"
					alt="asdf"
					class="col-12 col-md-2 col-lg-1 mx-md-5">
		<img 	src="assets/partners/p3.png"
					alt="asdf"
					class="col-12 col-md-2 col-lg-1 mx-md-5">
		<img 	src="assets/partners/p4.png"
					alt="asdf"
					class="col-12 col-md-2 col-lg-1 mx-md-5">
		<img 	src="assets/partners/p5.png"
					alt="asdf"
					class="col-12 col-md-2 col-lg-1 mx-md-5">
	</div>
	<div class="text-center mb-5">
		<img 	src="assets/partners/p7.png"
					alt="asdf"
					class="col-12 col-md-2 col-lg-1 mx-md-5">
		<img 	src="assets/partners/p8.png"
					alt="asdf"
					class="col-12 col-md-2 col-lg-1 mx-md-5">
		<img 	src="assets/partners/p9.png"
					alt="asdf"
					class="col-12 col-md-2 col-lg-1 mx-md-5">
		<img 	src="assets/partners/p10.png"
					alt="asdf"
					class="col-12 col-md-2 col-lg-1 mx-md-5">
		<img 	src="assets/partners/p11.png"
					alt="asdf"
					class="col-12 col-md-2 col-lg-1 mx-md-5">
		<img 	src="assets/partners/p12.png"
					alt="asdf"
					class="col-12 col-md-2 col-lg-1 mx-md-5">
	</div>
</div>
<footer class="proviaje-footer catalogue py-3">
	<div class="px-3 text-center">
		<strong>Copyright © {{actualYear}} proviaje.es</strong>
		Todos los derechos reservados.
		- Movil: 629 71 00 52 / 616 44 89 69 - E-mail: info@proviaje.es

		<div class="text-center">
			<a 	href="assets/aviso_legal.pdf"
					target="_blank">Aviso legal</a> -
			<a 	href="assets/politica_privacidad.pdf"
					target="_blank">Terminos y condiciones</a> -
			<a 	href="assets/proteccion_datos.pdf"
					target="_blank">Proteccion de datos</a>
		</div>
	</div>
</footer>
