import { Component, OnInit } from '@angular/core';
import { Seller } from 'src/app/classes/seller.class';
import Swal from 'sweetalert2';
import { DataMasterService, USER_TYPE_MOD, USER_TYPE_ADMIN } from 'src/app/services/data-master.service';

@Component({
  selector: 'app-admin-user-details',
  templateUrl: './admin-user-details.component.html',
  styleUrls: ['./admin-user-details.component.scss']
})
export class AdminUserDetailsComponent implements OnInit {

  sellerList : Seller[] = [];

  constructor(public dataService : DataMasterService) { }

  ngOnInit(): void {
    this.loadSellerList();
  }

  loadSellerList() {
    this.dataService.getAllSellers().subscribe(response =>{
      
      if (!response[0]) {
        //console.error(response[1]);
        Swal.close();
        this.dataService.showMessage('Error inesperado', 'Error inesperado en la base de datos.', 'error');
        return;
      }

      if (response[1] == "no result") {
        this.sellerList = [];
      } else {
        this.sellerList = response[1];
        this.sellerList = response[1].filter(item => item.seller_id != 0);
        console.log(this.sellerList);
      }
    });
  }

  toggleSellerStatusEvent(seller : Seller) {
    const status = seller.seller_status == "0" ? 1 : 0;
    this.dataService.setSellerStatus(seller.seller_id, status).subscribe(response => {
      if (!response[0]) {
        console.error(response[1]);
        Swal.close();
        this.dataService.showMessage('Error inesperado', 'Error inesperado en la base de datos.', 'error');
        return;
      }
      if (seller.seller_status == "0") {
        let localId = this.dataService.getUserId();
        this.dataService.insertLog(null, null, localId, "Usuario habilitado: "+ seller.seller_id).subscribe(responseLog=>{
          console.log(responseLog);
        });
      }else{
        let localId = this.dataService.getUserId();
        this.dataService.insertLog(null, null, localId, "Usuario deshabilitado: "+ seller.seller_id).subscribe(responseLog=>{
          console.log(responseLog);
        });
      }
      

      this.loadSellerList();
    });
  }

  deleteSellerEvent(seller : Seller) {
    this.dataService.showMessage({
      title: `Esta seguro de eliminar a ${seller.seller_name} ${seller.seller_lastname}?`,
      text: "Esta accion no se puede deshacer.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Eliminar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        this.tryDeleteSeller(seller);
      }
    });
  }

  private tryDeleteSeller(seller : Seller) {
    const seller_id = seller.seller_id;
    this.checkDeleteAvailability(seller_id).then(result => {
      if (result) {
        this.dataService.getLastRequest(seller_id).subscribe(response => {
          if (!response[0]) {
            console.error(response[1]);
            Swal.close();
            this.dataService.showMessage('Error inesperado', 'Error inesperado en la base de datos.', 'error');
            return;
          }

          if (response[1] == "no result") {
            this.deleteSeller(seller_id);
          } else {
            const request = response[1][0];
            this.deleteLastSellerRequest(request.request_id, seller_id);
          }
        });
      } else {
        this.dataService.showMessage("El alumno no puede ser eliminado", "El alumno que desea eliminar tiene pedidos tramitados.", "error");
      }
    });
  }
  private checkDeleteAvailability(seller_id : number) : Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.dataService.getRequestUser(seller_id).subscribe(response => {
        if (!response[0]) {
          console.error(response[1]);
          Swal.close();
          this.dataService.showMessage('Error inesperado', 'Error inesperado en la base de datos.', 'error');
          return;
        }

        if (Array.isArray(response[1]) &&
          response[1].filter(item => item.request_date != null).length > 0) {
          resolve(false);
          return;
        }
        resolve(true);
      });
    });

  }

  private deleteLastSellerRequest(request_id : number, seller_id : number) {
    this.dataService.deleteRequest(request_id).subscribe(response => {
      if (!response[0]) {
        console.error(response[1]);
        Swal.close();
        this.dataService.showMessage('Error inesperado', 'Error inesperado en la base de datos.', 'error');
        return;
      }

      this.deleteSeller(seller_id);
    });
  }

  private deleteSeller(seller_id : number) {
    this.dataService.deleteUser(seller_id).subscribe(response => {

      if (!response[0]) {
        console.error(response[1]);
        Swal.close();
        this.dataService.showMessage('No se ha podido eliminar el alumno', '', 'error');
        let localId = this.dataService.getUserId();
        this.dataService.insertLog(null, null, localId, "Error al eliminar usuario").subscribe(responseLog=>{
          console.log(responseLog);
        });
        return;
      }

      if (response[0] == true) {
        this.dataService.showMessage('Usuario eliminado', '', 'success');
        let localId = this.dataService.getUserId();
        this.dataService.insertLog(null, null, localId, "Usuario eliminado").subscribe(responseLog=>{
          console.log(responseLog);
        });
        this.loadSellerList();
      }
    });
  }
}
