import { Component, OnInit, Input, ViewChild, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

import { Log } from 'src/app/classes/log.class';

@Component({
  selector: 'app-table-log',
  templateUrl: './table-log.component.html',
  styleUrls: ['./table-log.component.scss']
})
export class TableLogComponent implements OnInit {

  @Input() logList : Log[]=[];
  displayedColumns = ['id', 'email', 'name', 'lastname', 'institution', 'description', 'date'];;
  logDataSource: MatTableDataSource<Log>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  paddingColumnsResponsive: boolean;

  constructor() {
    this.logDataSource = new MatTableDataSource(this.logList);
  }

  ngOnChanges(changes: SimpleChanges) : void {
    

    if (changes['logList'] != null) {
      this.logDataSource.data = this.logList;
      this.logDataSource.paginator = this.paginator;
      this.logDataSource.sort = this.sort;
    }
  }

  ngOnInit(): void {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.paddingColumnsResponsive = true; 
    }else{
      this.paddingColumnsResponsive = false; 
    }
  }

  sortData(sort: MatSort) {
    const data = this.logList;
    this.logList = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name':
          return compare(a.name, b.name, isAsc);
        case 'lastname':
          return compare(a.lastname, b.lastname, isAsc);
        case 'institution':
          return compare(a.institution, b.institution, isAsc);
        case 'id':
          return compare(a.id, b.id, isAsc);
        case 'email':
          return compare(a.email, b.email, isAsc);
        case 'date':
          return compare(a.date, b.date, isAsc);
        default:
          return 0;
      }
    });
    this.logDataSource.data = this.logList;
  }

  filter(event) : void {
    this.logDataSource.filter = event.target.value.trim().toLowerCase();
  }

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

